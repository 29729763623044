@import url('https://fonts.googleapis.com/css?family=Suez+One&display=swap');
@import url('https://fonts.googleapis.com/css?family=Bellota+Text&display=swap');
@import url('https://fonts.googleapis.com/css?family=Ubuntu&display=swap');

/* @font-face {
    font-family: Rembank;
    src: url("./font/Rembank.otf") format("opentype");
} */

.App {
    /* background: linear-gradient(120deg, rgb(255, 93, 64), rgb(255, 155, 78)); */
    background-image: url("./background.svg");
    background-size: cover;
    background-repeat: no-repeat;
}

h1 {
    font-family: 'Suez One';
}

h2,
h3,
h4 {
    font-family: 'Suez One';
}

body {
    font-family: "Ubuntu";
}

.full-screen {
    height: 100vh;
}

.banner-screen {
    height: calc(100vh + 100px);
}

.full-w {
    width: 100%;
}

.align-left {
    text-align: left;
}

.align-right {
    text-align: right;
}

.align-center {
    text-align: center;
}

.align-justify {
    text-align: justify;
}

.bg-nav {
    /* backdrop-filter: blur(10px); */
    z-index: 20;
}

.qualif {
    /* right: calc(100vw/10);
    opacity: 0;
    transform: translateX(-50%); */
    opacity: 0;
}

.tiles {
    border-radius: 15px;
    margin-inline: 1.5rem;
    margin-bottom: 20px;
    padding: 7px;
    background-color: rgba(0, 0, 0, 0.180);
    overflow: hidden;
    /* box-shadow: 0 0 60px rgba(0, 0, 0, 0.281); */
    max-height: 500px;
}

.tilesm {
    border-radius: 15px;
    margin-bottom: 20px;
    padding: 7px;
    background-color: rgba(0, 0, 0, 0.180);
    overflow: hidden;
    /* box-shadow: 0 0 60px rgba(0, 0, 0, 0.281); */
    max-height: 500px;
}

.full-x {
    width: 100%;
}

.full-y {
    height: 100%;
}

.stone {
    background: #fff3;
    border-radius: 20px 20px 250px 250px/20px 20px 100px 100px;
    box-shadow: 0 0 60px rgba(0, 0, 0, 0.281);
    margin-inline: 1.5rem;
    margin-bottom: 45px;
}

.drop-card {
    background: linear-gradient( #0000, rgba(255, 255, 255, 0.2), rgba(255, 255, 255, 0.25), rgba(255, 255, 255, 0.2), #0000);
    border-radius: 10px;
    /* box-shadow: 0 0 60px rgba(0, 0, 0, 0.281); */
}

.text-overlay {
    background: -webkit-gradient(linear, left top, right top, from(cyan), to(orange));
    background: linear-gradient(to right, cyan, orange);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    z-index: 1;
}

.hinted {
    background-color: #081600;
    box-shadow: 0 0 15px rgba(0, 0, 0, 0.323);
    display: inline;
    padding: 0.45rem;
    box-decoration-break: clone;
    -webkit-box-decoration-break: clone;
    line-height: 1.35;
    padding-left: 020px;
    padding-right: 20px;
    padding-top: 0px;
    padding-bottom: 0px;
}